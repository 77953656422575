import { A } from '@solidjs/router';
import { Show } from 'solid-js';
import IconFacebookWhite from '~/assets/images/home/facebookWhite.svg';
import IconInstagramWhite from '~/assets/images/home/instagramWhite.svg';
import IconLinkedinWhite from '~/assets/images/home/linkedinWhite.svg';
import IconTk from '~/assets/images/home/tkWhite.svg';
import IconTwitterWhite from '~/assets/images/home/twitterWhite.svg';
import IconYoutubeWhite from '~/assets/images/home/youtubeWhite.svg';
import { FOLLOW_US, EMAIL, PHONE } from '~/assets/strings';
import { Skeleton } from '~/components/common/Skeleton';
import { useLocalization } from '~/hooks/useLocalization';
import { PresentableCompayStyle } from '~/presenters/CompanyStylePresenter';
interface FooterProps {
  companyStyling: PresentableCompayStyle | undefined;
}

export const Footer = (props: FooterProps) => {
  const { t } = useLocalization();
  return (
    <div class="mt-[118px] flex w-full flex-col items-center justify-center bg-[#2C2C32]">
      <div class="flex  w-max-container-width  flex-col ">
        <div class="flex w-full items-center justify-between border-b border-white/20 py-5">
          <div class="flex items-center gap-10">
            <A href="/company-website">
              <img src={props?.companyStyling?.logo} alt="logo" class="h-[50px]" />
            </A>
          </div>

          <div class="flex items-center gap-[20px] text-white ">
            <div class="text-sm">{t(FOLLOW_US)}:</div>
            <div class="flex items-center gap-2">
              <Show when={props?.companyStyling?.facebook}>
                <A href={props?.companyStyling?.facebook ?? ''} target="_blank" class="rounded-md p-2 hover:bg-hover-color">
                  <img class="size-[14px]" src={IconFacebookWhite} />
                </A>
              </Show>

              <Show when={props?.companyStyling?.instagram}>
                <A href={props?.companyStyling?.instagram ?? ''} target="_blank" class="rounded-md p-2 hover:bg-hover-color">
                  <img src={IconInstagramWhite} />
                </A>
              </Show>

              <Show when={props?.companyStyling?.linkedIn}>
                <A href={props?.companyStyling?.linkedIn ?? ''} target="_blank" class="rounded-md p-2 hover:bg-hover-color">
                  <img src={IconLinkedinWhite} />
                </A>
              </Show>

              <Show when={props?.companyStyling?.twitter}>
                <A href={props?.companyStyling?.twitter ?? ''} target="_blank" class="rounded-md p-2 hover:bg-hover-color">
                  <img src={IconTwitterWhite} />
                </A>
              </Show>

              <Show when={props?.companyStyling?.youtube}>
                <A href={props?.companyStyling?.youtube ?? ''} target="_blank" class="rounded-md p-2 hover:bg-hover-color">
                  <img src={IconYoutubeWhite} />
                </A>
              </Show>

              <Show when={props?.companyStyling?.tikTok}>
                <A href={props?.companyStyling?.tikTok ?? ''} target="_blank" class="rounded-md p-2 hover:bg-hover-color">
                  <img src={IconTk} />
                </A>
              </Show>
            </div>
          </div>
        </div>
      </div>

      <div class="flex h-10 w-full items-center justify-center bg-[#24242B] text-white">
        <div class="flex w-max-container-width  items-center justify-between ">
          <div>©2024 All Rights</div>
          <div class="flex items-center gap-10">
            <div class="flex items-center gap-2">
              <span class="text-sm ">{t(EMAIL)}:</span>
              <Show when={props?.companyStyling?.email} fallback={<Skeleton class="w-[180px]" />}>
                <span class="text-sm ">{props?.companyStyling?.email}</span>
              </Show>
            </div>

            <div class="flex items-center gap-2">
              <span class="text-sm ">{t(PHONE)}:</span>
              <Show when={props?.companyStyling?.phoneNumber} fallback={<Skeleton class="w-[180px]" />}>
                <span class="text-sm ">{props?.companyStyling?.phoneNumber}</span>
              </Show>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
