import { A, useLocation } from '@solidjs/router';
import { For, Show } from 'solid-js';
import IconSelected from '~/assets/images/home/menuSelected.svg';
import { HOME, PROPERTIES } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';
import { cn } from '~/utils/classnames';

export interface MenuProps {
  title: string;
  link: string;
}

export const menus = [
  {
    title: HOME,
    link: '/company-website',
  },
  {
    title: PROPERTIES,
    link: '/company-website/properties',
  },
];

export const Menu = () => {
  const location = useLocation();
  const { t } = useLocalization();
  return (
    <div class="flex items-center gap-6">
      <For each={menus}>
        {(item) => (
          <A href={item.link}>
            <div class="relative flex flex-col items-center justify-center gap-[5px]">
              <div class={cn('text-[#656C81]', item.link === location.pathname && 'text-primary-color')}>{t(item.title)}</div>
              <Show when={item.link === location.pathname}>
                <img class="absolute bottom-[-10px]" src={IconSelected} alt="" />
              </Show>
            </div>
          </A>
        )}
      </For>
    </div>
  );
};
