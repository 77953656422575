import { JSX } from 'solid-js';
import { Footer } from '~/components/layouts/Footer';
import { Header } from '~/components/layouts/Header';
import { useStyling } from '~/global-contexts/CompanyStyling';

export interface LayoutProps {
  children: JSX.Element;
}

export default function Home(props: LayoutProps) {
  const { companyStyling } = useStyling();

  return (
    <div class="flex h-screen w-full flex-col items-center">
      <Header companyStyling={companyStyling?.()} />
      <div class="mt-[135px] w-full">{props.children}</div>
      <Footer companyStyling={companyStyling?.()} />
    </div>
  );
}
